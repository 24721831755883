.email_field input {
  border: 2px solid #dadada;
  outline: none;
  border-radius: 5px;
  /* height: 56px; */
  /* width: 356px; */
  color: #000000bc;
  padding: 17px 16.5px;
  font-size: 16px;
  line-height: 24px;
  transition: 300ms ease all;
  /* position: relative;
  z-index: 98; */
}

.email_field input:focus {
  border: 2px solid #3C6BA5;
  /* transition: 300ms ease all; */
}

.passwordIcon {
  position: relative;
}

.passwordIcon .iconify {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.login_btn {
  background: linear-gradient(180deg, #ffb955 0%, #e3398b 100%);
  width: fit-content;
  color: #ffffff;
  letter-spacing: 1.25px;
  /* transition: 5s ease all; */
  outline: none;
  width: 77px;
  height: 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login_btn:hover,
.newacc_btn:hover {
  background: linear-gradient(180deg, #3C6BA5 0%, #3C6BA5 100%);
  transition: 1s ease all;
}

.newacc_btn {
  margin-top: 20px;
  letter-spacing: 1.25px;
  background: linear-gradient(180deg, #1d2630 0%, #1d2630 100%);
  width: fit-content;
  transition: 1s ease all;
  cursor: pointer;
}
